import { Filter } from "../models/AppointmentData";

export const days = ['Sun', 'Mon', 'Tues', 'Wed', 'Thurs', 'Fri', 'Sat'];
export const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];

export function filterToQuery(filters: Filter, order: string, orderBy: string, postalCode: string | null, existingParams: Record<string, string>): string {
    const params = new URLSearchParams();

    // Keep existing params except for the ones being set here
    for (const key of Object.keys(existingParams)) {
        if (key !== "availableSlots" && key !== "city" && key !== "region" && key !== "dose" && key !== "vaccineType") {
            params.set(key, existingParams[key]);
        }
    }

    if (filters.city.length > 0) {
        params.set("city", filters.city.join(","))
    }
    if (filters.region.length > 0) {
        params.set("region", filters.region.join(","));
    }
    if (filters.availableSlots === true) {
        params.set("availableSlots", filters.availableSlots.toString());
    }
    if (filters.dose !== 'all') {
        params.set("dose", filters.dose);
    }
    if (filters.vaccineType !== 'all') {
        params.set("vaccineType", filters.vaccineType);
    }
    if (postalCode) {
        params.set("postalCode", postalCode);
    }
    params.set("order", order);
    params.set("orderBy", orderBy);

    // URLSearchParams.toString() does not include the 'q'
    return `?${params.toString()}`;
}

export const getLocalizedDateTime = (date: number | Date | undefined, formatter: Intl.DateTimeFormat): string => {

    if (!date) {
        return '';
    }

    // Example Sat, May 22, 8:18 AM
    return formatter.format(date);
};

export function objectHasKey(object: Record<string, unknown>, key: string): boolean {
    return Object.prototype.hasOwnProperty.call(object, key);
}

export function formatDistance(distance: number): string {
    // Round to 1 decimal place
    return `${Math.round(distance / 100) / 10} km`;
}

export function clinicLocationIsValid(location: { lat?: number; lng?: number }): location is { lat: number, lng: number } {
    return typeof location.lat !== 'undefined' && typeof location.lng !== 'undefined';
}
