import React, { useState } from 'react';
import {Trans} from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Link from '@material-ui/core/Link';
import i18next from 'i18next';

const Accordion = withStyles({
  root: {
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: 'rgba(0, 0, 0, .03)',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiAccordionDetails);

export default function CustomizedAccordions(): React.ReactElement {
  const [expanded, setExpanded] = useState<string | false>();

  const handleChange = (panel: string) => (event: React.ChangeEvent<Record<string, unknown>>, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : false);
  };

  const items = [
    { question: "q_what_is_the_goal", answer: "a_what_is_the_goal"},
    { question: "q_who_are_we", answer: "a_who_are_we"},
    { question: "q_second_dose", answer: "a_second_dose"},
    { question: "q_acknowledgement", answer: "a_acknowledgement"},
  ]

  return (
    <div>
      <Typography dir={i18next.dir()} component="h2" variant="h6" color="primary" gutterBottom>
        <Trans>faq</Trans>
      </Typography>
      {items.map((value, index) => (
        <Accordion key={index} expanded={expanded === 'panel' + index} onChange={handleChange('panel' + index)}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={"panel" + index + "d-content"} id={"panel" + index + "d-header"}>
            <Typography dir={i18next.dir()}><Trans>{value.question}</Trans></Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <Trans>{value.answer}</Trans>
            </Typography>
          </AccordionDetails>
        </Accordion>
      ))}
      <Accordion expanded={expanded === 'panelContact'} onChange={handleChange('panelContact')}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography dir={i18next.dir()}><Trans>q_contact_us</Trans></Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography dir={i18next.dir()}>
            <Trans i18nKey="a_contact_us">
              {/* The text before and after the link doesn't matter, but is needed for i18n to match */}
              a<Link href="mailto:feedback@vaccine-ontario.ca">feedback@vaccine-ontario.ca</Link>b
            </Trans>
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}