import React, { Suspense } from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import Home from './components/Home/Home';
import LinearProgress from '@material-ui/core/LinearProgress';

function App(): React.ReactElement {
    return (
      <Router>
        <Suspense fallback={<LinearProgress />}>
          <Switch>
            <Route path="/" exact component={Home} />
            <Redirect to="/" />
          </Switch>
        </Suspense>
      </Router>
    );
}

export default App;