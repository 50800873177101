import firebase from 'firebase/app'
import 'firebase/analytics';
import 'firebase/firestore';

const firebaseConfig = {
    apiKey: "AIzaSyAFCaO1gznNWEHNRACMKB2U9inQcYO96uI",
    authDomain: "vaxhunterstoronto.firebaseapp.com",
    databaseURL: "https://vaxhunterstoronto-default-rtdb.firebaseio.com",
    projectId: "vaxhunterstoronto",
    storageBucket: "vaxhunterstoronto.appspot.com",
    messagingSenderId: "275352429523",
    appId: "1:275352429523:web:a53abb06243ae800ff4526",
    measurementId: "G-2M8MVGGBZ9"
};

const firebaseApp = firebase.initializeApp(firebaseConfig);
firebase.firestore().enablePersistence({ synchronizeTabs: true })
    .catch((err) => {
        console.error(err);
    });
// Subsequent queries will use persistence, if it was enabled successfully
const db = firebase.firestore();
firebaseApp.analytics();           // re-able analytics to see traffic volume

export default db;