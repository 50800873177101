import { fromUnixTime, parse, startOfDay, startOfWeek } from 'date-fns';

export type Order = 'asc' | 'desc';
export type Dose = 'all' | 'first' | 'second';
export type VaccineType = 'all' | 'pfizer' | 'moderna' | 'mrna' | 'astrazeneca';
export interface GetOptions {
    source?: 'default' | 'server' | 'cache';
}

export interface ISlot {
    date: Date;
    slots: number;
}

function createSlot(date: Date, slots: number): ISlot {
    return { date, slots };
}

export interface AppointmentProps {
    filters: Filter,
    clinics: IClinic[],
    filteredClinics: IClinic[],
    global: IGlobal,
    order: Order,
    orderBy: keyof IClinic | "distance",
    location: { lat: number, lng: number } | null,
    setOrder: React.Dispatch<React.SetStateAction<Order>>,
    setOrderBy: React.Dispatch<React.SetStateAction<keyof IClinic | "distance">>,
    maxDate: Date,
    postalCode: string | null,
    setPostalCode: React.Dispatch<React.SetStateAction<string | null>>,
    setAllClinicFilters: (clinics: IClinic[], filter: Filter) => void
    loading?: boolean
}
export interface IClinic {
    id: number;
    name: string;
    name_translated: { [key: string]: string };
    city: string;
    city_translated: { [key: string]: string };
    link: string;
    address: string;
    vaccine_type: string;
    eligibility: string;
    updated_on?: Date;
    region: string;
    availableSlots: boolean;
    location: {
        lat?: number,
        lng?: number,
    };

    // Slots mapped by day. Each key is a unix millisecond timestamp
    slots: Map<number, ISlot>;
    latestDate: Date;
    dose: 'default' | 'first' | 'second';
}
export function createClinic(id: number, data: any): IClinic {
    const slots: Map<number, ISlot> = new Map();
    const currentDate = startOfDay(Date.now());

    // The date that has data furthest in the future
    let latestDate = startOfWeek(currentDate, { weekStartsOn: 1 });
    let availableSlots = false;

    for (const dt of Object.keys(data.slots_left)) {
        const date = startOfDay(parse(dt, 'yyyy-MM-dd', new Date()));
        const slotCount = data.slots_left[dt];

        if (slotCount > 0) {
            availableSlots = true;
            if (date > latestDate) {
                latestDate = date;
            }
        }

        slots.set(date.getTime(), createSlot(date, slotCount));
    }

    const clinicName = data.name ? data.name.trim() : "CLINIC NAME";
    const clinicCity = data.city ? data.city.trim() : "CITY NAME";
    const clinicRegion = data.healthUnit ? data.healthUnit.trim() : "REGION NAME";
    const clinicDose = data.second_dose === true ? 'second' : 'first';
    let updated_on = undefined;

    if (data.updated_on.seconds != undefined)
        updated_on = fromUnixTime(data.updated_on.seconds);
    else if (data.updated_on._seconds != undefined)
        updated_on = fromUnixTime(data.updated_on._seconds);

    return {
        id,
        name: clinicName,
        name_translated: data.name_translated,
        city: clinicCity,
        city_translated: data.city_translated,
        link: data.registration_link,
        address: data.address,
        updated_on: updated_on,
        vaccine_type: data.vaccineType,
        eligibility: data.eligibility,
        region: clinicRegion,
        availableSlots,
        slots,
        latestDate,
        location: {
            lat: data?.location?.lat,
            lng: data?.location?.lng,
        },
        dose: clinicDose
    };
}
export interface Filter {
    availableSlots: boolean;
    region: string[];
    city: string[];
    dose: Dose;
    vaccineType: VaccineType;
}
export interface IGlobal {
    last_getdata_on: Date;
    next_getdata_on: Date;
}

export function createGlobal(last_getdata_on: Date, next_getdata_on: Date): IGlobal {
    return { last_getdata_on: new Date(last_getdata_on), next_getdata_on: new Date(next_getdata_on) };
}

export function getSlotClass(available?: number): string {
    let slotClass = '';

    if (typeof available === 'number') {
        if (available >= 10) {
            slotClass = 'available';
        } else if (available > 0) {
            slotClass = 'low';
        }
    }

    return slotClass;
}

export function getSlotText(available?: number, debug = false): string {
    let text = '-';

    // Debug shows the difference between '0' and '-' (no data)
    // Whereas prod shows 0 and no data the same as a '-'
    const limit = debug ? 0 : 1;
    if (typeof available === 'number' && available >= limit) {
        text = available.toString();
    }

    return text;
}
