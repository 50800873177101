import React from 'react';
import { Trans } from 'react-i18next';
import { createStyles, Theme, withStyles, WithStyles, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import NotificationsIcon from '@material-ui/icons/Notifications';
import Link from '@material-ui/core/Link';
import i18next from 'i18next';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center'
    },
    closeButton: {
      color: theme.palette.grey[500],
    },
    typography: {
      width: "90%"
    },
  });

const useStyles = makeStyles({
  mobileButton: {
    width: 48,
    height: 48,
  },
  mobileIcon: {
    marginRight: 0,
    marginLeft: 0,
  },
  mobileIconSize: {
    fontSize: 32,
  }
});

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography className={classes.typography} variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

export default function CustomizedDialogs(): React.ReactElement {
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const classes = useStyles();

  return (
    <div>
      <Button
        dir={i18next.dir()}
        startIcon={<NotificationsIcon style={{ fontSize: 32 }} />}
        variant="outlined"
        color="inherit"
        onClick={handleClickOpen}
        classes={{
          root: classes.mobileButton,
          startIcon: classes.mobileIcon,
        }}
      >
        <span style={{
          width: 16,
          height: 16,
          borderRadius: 8,
          fontSize: 12,
          lineHeight: '16px',
          backgroundColor: 'black',
          position: 'absolute',
          right: 15,
          top: 2
        }}>1</span>
      </Button>
      <Dialog dir={i18next.dir()} onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          <Trans>notification_cta</Trans>
        </DialogTitle>
        <DialogContent dividers>
          <Typography dir={i18next.dir()} gutterBottom>
            <Trans i18nKey="notification_google_group">
              a<Link href={"https://groups.google.com/g/vaxhunterstoronto"} target="_blank" rel="noopener noreferrer">VaxHuntersToronto Google Groups</Link>b
            </Trans>
          </Typography>
          <Typography dir={i18next.dir()} gutterBottom>
            <Trans i18nKey="notification_twitter">
              a<Link href="https://twitter.com/VaxToronto" target="_blank" rel="noopener noreferrer">@VaxToronto</Link><Link href="https://twitter.com/VaxYork" target="_blank" rel="noopener noreferrer">@VaxYork</Link><Link href="https://twitter.com/VaxDurham" target="_blank" rel="noopener noreferrer">@VaxDurham</Link><Link href="https://twitter.com/VaxPeel" target="_blank" rel="noopener noreferrer">@VaxPeel</Link>
            </Trans>
          </Typography>
        </DialogContent>
      </Dialog>
    </div>
  );
}