import React, { useEffect, useState, lazy, Fragment, Suspense } from 'react';
import { startOfDay } from 'date-fns';
import { useTranslation } from 'react-i18next';
import './Home.css';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Faq from '../Faq/Faq';
import Slide from '@material-ui/core/Slide';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import NotificationDialog from '../NotificationDialog/NotificationDialog';
import { useRouter } from '../../utils/router';
import { createClinic, createGlobal, Dose, Filter, IClinic, IGlobal, Order, VaccineType } from '../../models/AppointmentData';
import db from '../../firebase.config';
import { filterToQuery } from '../../utils/utils';
import LinearProgress from '@material-ui/core/LinearProgress';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import DateRangeIcon from '@material-ui/icons/DateRange';
import HelpIcon from '@material-ui/icons/Help';
import Select from '@material-ui/core/Select';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import LanguageIcon from '@material-ui/icons/Language';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

import './Home.css';

const AppointmentViewMobile = lazy(() => import('../AppointmentViewMobile/AppointmentViewMobile'));
const AppointmentTable = lazy(() => import('../Table/AppointmentTable'));

// The list of languages to show in switch menus
const languages = [
  ["en", "English"],
  ["fr", "Français"],
  // ["am", "አማርኛ"], // Amharic
  ["ar", "العربية"], // Arabic
  ["bn", "বাংলা"], // Bengali
  ["bg", "български"], // Bulgarian
  ["bs", "bosanski"], // Bosnian
  ["de", "Deutsch"], // German
  ["es", "Español"],
  ["fa", "فارسى"], // Farsi
  ["gu", "ગુજરાતી"], // Gujarati
  ["hi", "हिंदी"], // Hindi
  ["hr", "hrvatski"], // Croatian
  // ["hu", "magyar"], // Hungarian
  ["id", "Bahasa Indonesia"], // Indonesian
  ["it", "italiano"], // Italian
  ["ko", "한국어"], // Korean
  ["pl", "Polski"],
  ["pt", "Português (BR)"], // Portuguese
  ["ro", "română"], // Romanian
  ["ru", "русский"], // Russian
  ["si", "සිංහල"], // Sinhala
  ["sq", "Shqiptare"], // Albanian
  ["sr-Latn", "srpski"], // Serbian
  ["sr-Latn-ME", "crnogorski"], // Montenegrin
  ["ta", "தமிழ்"], // Tamil
  ["th", "ไทย"], // "Thai"
  ["tl", "Wikang Tagalog"], // "Tagalog"
  // ["tr", "Türkçe"], // Turkish
  ["ur", "اُردو"], // Urdu
  ["vi", "Tiếng Việt"], // Vietnamese
  ["zh-CN", "简体中文"],
  ["zh-TW", "繁体中文"],
];

const HideOnScroll = (props: { children: React.ReactElement }) => {
  const trigger = useScrollTrigger()
  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {props.children}
    </Slide>
  )
}

export default function Home(): React.ReactElement {
  const router = useRouter();
  const cityFilter: string[] = router.query.city ? router.query.city.split(',') : [];
  const regionFilter: string[] = router.query.region ? router.query.region.split(',') : [];
  const doseFilter: Dose = router.query.dose ?? 'all';
  const vaccineTypeFilter: VaccineType = router.query.vaccineType ?? 'all';
  const debug: boolean = router.query.debug === 'true';

  const [width, setWidth] = useState<number>(window.innerWidth);
  const [filters, setFilters] = useState<Filter>({
    availableSlots: router.query.availableSlots === 'true',
    region: regionFilter,
    city: cityFilter,
    dose: doseFilter,
    vaccineType: vaccineTypeFilter,
  });
  const [clinics, setClinics] = useState<IClinic[]>([]);
  const [filteredClinics, setFilteredClinics] = useState<IClinic[]>([]);
  const [global, setGlobal] = useState<IGlobal>();
  const [tab, setTab] = useState(0);
  const [maxDate, setMaxDate] = useState<Date>(new Date());

  // pagination, filtering and ordering
  const [order, setOrder] = useState<Order>(router.query.order ?? 'asc');
  const [orderBy, setOrderBy] = useState<keyof IClinic | "distance">(router.query.orderBy ?? 'city');
  const [postalCode, setPostalCode] = useState<string | null>(router.query.postalCode ?? null);
  const [location, setLocation] = useState<{ lat: number, lng: number } | null>(null);
  const [loading, setLoading] = useState(true);

  const isMobile = width <= 768;
  const { i18n, t } = useTranslation();
  const url = '/clinics.json';

  const fetchData = async () => {

    try {
      const response = await fetch(url);
      
      const tempClinics: IClinic[] = [];
      let i = 0;
      const responseJson = await response.json();

      for (const doc of responseJson.clinics) {
        if (debug || !doc.as_deleted) {
            tempClinics.push(createClinic(i++, doc));
            // console.log(doc.name);
        }
      }
      const dayStart = startOfDay(Date.now());
      const maxDataDate = tempClinics.reduce((max, clinic) => clinic.latestDate > max ? clinic.latestDate : max, dayStart);
      setMaxDate(maxDataDate);
      setAllClinicFilters(tempClinics, filters);
      setClinics(tempClinics);

      const site = responseJson.site;
      console.log(site);

      const last_getdata_on = site.last_getdata_on._nanoseconds / 1000000 + site.last_getdata_on._seconds * 1000;
      const next_getdata_on = site.next_getdata_on._nanoseconds / 1000000 + site.next_getdata_on._seconds * 1000;
      const createdGlobal = createGlobal(new Date(last_getdata_on), new Date(next_getdata_on));
      setGlobal(createdGlobal);

      setLoading(false);
      console.log(`fetched ${responseJson.clinics.length} clinics`);

    } catch (e) {
      console.error(e);
      console.log('fallback to DB call');
      fetchDataDB();
    }

  }

  const fetchDataDB = async () => {
    
    let getServer = false;
    const cacheSnapshot = await db.collection('global').get({ source: 'cache' });

    if (!cacheSnapshot.empty) {
      console.log("Cache hit!")
      cacheSnapshot.forEach(doc => {
        const last_getdata_on = doc.data().last_getdata_on.nanoseconds / 1000000 + doc.data().last_getdata_on.seconds * 1000;
        const lastUpdatedDate = new Date(last_getdata_on + 1000 * 60 * 5);

        if (new Date(Date.now()) > lastUpdatedDate) {
          console.log("Cache data is over 5 minutes old - getting from server");
          getServer = true;
        } else {
          console.log("Cache data is less than 5 minutes old - getting from cache");
          const next_getdata_on = doc.data().next_getdata_on.nanoseconds / 1000000 + doc.data().next_getdata_on.seconds * 1000;
          const createdGlobal = createGlobal(new Date(last_getdata_on), new Date(next_getdata_on));
          setGlobal(createdGlobal);
        }
      })
    } else {
      getServer = true;
    }

    if (getServer) {
      db.collection('global').get({ source: 'server' }).then((querySnapshot) => {
        querySnapshot.forEach(doc => {
          const last_getdata_on = doc.data().last_getdata_on.nanoseconds / 1000000 + doc.data().last_getdata_on.seconds * 1000;
          const next_getdata_on = doc.data().next_getdata_on.nanoseconds / 1000000 + doc.data().next_getdata_on.seconds * 1000;
          const createdGlobal = createGlobal(new Date(last_getdata_on), new Date(next_getdata_on));
          setGlobal(createdGlobal);
        });
      });
    }

    console.log("Fetching clinics from " + (getServer ? 'server' : 'cache'));
    db.collection('clinic').get({ source: getServer ? 'server' : 'cache' }).then(querySnapshot => {
      const tempClinics: IClinic[] = [];
      let i = 0;
      querySnapshot.forEach((doc) => {
        if (debug || !doc.data().as_deleted)
          tempClinics.push(createClinic(i++, doc.data()));
      });

      const dayStart = startOfDay(Date.now());
      const maxDataDate = tempClinics.reduce((max, clinic) => clinic.latestDate > max ? clinic.latestDate : max, dayStart);
      setMaxDate(maxDataDate);
      setAllClinicFilters(tempClinics, filters);
      setClinics(tempClinics);
      setLoading(false);
    });
  };

  function setAllClinicFilters(allClinics: IClinic[], filter: Filter): void {
    let tempClinics: IClinic[] = filter.availableSlots === true ? allClinics : allClinics.filter(clinic => (clinic.availableSlots === true));
    tempClinics = filter.dose !== 'all' ? tempClinics.filter(clinic => (clinic.dose === filter.dose)) : tempClinics;
    tempClinics = filter.region.length > 0 ? tempClinics.filter((clinic) => filter.region.includes(clinic.region)) : tempClinics;
    tempClinics = filter.city.length > 0 ? tempClinics.filter((clinic) => filter.city.includes(clinic.city)) : tempClinics;
    tempClinics = filter.vaccineType !== 'all' ? tempClinics.filter(clinic => {
      const clinicVaccineType = clinic.vaccine_type.toLowerCase();

      if (filter.vaccineType === 'mrna') {
        // Pfizer or Moderna
        return clinicVaccineType.includes("pfizer") || clinicVaccineType.includes("moderna");
      }

      return clinicVaccineType.includes(filter.vaccineType);
    }) : tempClinics;
    router.push(filterToQuery(filter, order, orderBy, postalCode, router.query));
    setFilteredClinics(tempClinics);
    setFilters(filter);
  }

  // Update the URL when the order changes
  useEffect(() => {
    router.push(filterToQuery(filters, order, orderBy, postalCode, router.query));
  }, [order, orderBy, postalCode])


  useEffect(() => {
    fetchData();
    const listener = () => setWidth(window.innerWidth);
    window.addEventListener('resize', listener);
    return () => window.removeEventListener('resize', listener);
  }, []);

  useEffect(() => {
    if (postalCode === null) {
      return;
    }

    fetch(`/postalcode?postalcode=${postalCode}`)
      .then(response => {
        response.json().then(data => {
          const result = data['postalcodes'][0];
          if (result) {
            setLocation({ lat: result.lat, lng: result.lng });
          } else {
            throw 'Invalid Postal Code'
          }
        }).catch(e => {
          console.error(e)
        });
      }).catch(e => {
        console.error(e)
        console.error("Failed to translate postal code to lat/long");
      })
  }, [postalCode]);

  const [menuAnchorElement, setMenuAnchorElement] = useState<(EventTarget & HTMLButtonElement) | null>(null);

  return (
    <div className="home" >
      <CssBaseline />
      <HideOnScroll>
        {/* On mobile, the virtual scrolling handles the toolbar being fixed so we don't need extra props here */}
        <AppBar position={'fixed'}>
          <Toolbar className="home-toolbar">
            <h5 className="home-title">
              vaccine-ontario.ca
            </h5>
            {!isMobile && (
              <Fragment>
                <LanguageIcon style={{ fontSize: 32 }} />
                <Select
                  className="home-language-select"
                  native
                  value={i18n.language}
                  onChange={(event) => {
                    // @ts-ignore
                    i18n.changeLanguage(event.target.value);
                  }}
                >
                  {languages.map(language => (
                    <option key={language[0]} value={language[0]}>{language[1]}</option>
                  ))}
                </Select>
              </Fragment>
            )}
            <NotificationDialog />
          </Toolbar>
        </AppBar>
      </HideOnScroll>
      <main className="home-content">
        {!isMobile && <div className="home-toolbar" />}
        <div className={isMobile ? "home-container-base home-container-mobile" : "home-container-base home-container"}>
          {tab === 0 &&
            <div className="home-grid">
              <div className="home-grid-item">
                {!isMobile && clinics.length > 0 &&
                  <Suspense fallback={<LinearProgress />}>
                    <div className="home-paper">
                      <AppointmentTable
                        clinics={clinics}
                        filters={filters}
                        filteredClinics={filteredClinics}
                        global={global ? global : { last_getdata_on: new Date(), next_getdata_on: new Date() }}
                        location={location}
                        order={order}
                        orderBy={orderBy}
                        setOrder={setOrder}
                        setOrderBy={setOrderBy}
                        setAllClinicFilters={setAllClinicFilters}
                        maxDate={maxDate}
                        setPostalCode={setPostalCode}
                        postalCode={postalCode}
                      />
                    </div>
                  </Suspense>
                }
                {isMobile &&
                  <Suspense fallback={<div className="loading" > <CircularProgress /></div>}>
                    {loading && <div className="loading" > <CircularProgress /></div>}
                    {!loading &&
                      < AppointmentViewMobile
                        clinics={clinics}
                        filters={filters}
                        filteredClinics={filteredClinics}
                        global={global ? global : { last_getdata_on: new Date(), next_getdata_on: new Date() }}
                        location={location}
                        order={order}
                        orderBy={orderBy}
                        setOrder={setOrder}
                        setOrderBy={setOrderBy}
                        setAllClinicFilters={setAllClinicFilters}
                        maxDate={maxDate}
                        setPostalCode={setPostalCode}
                        postalCode={postalCode}
                        loading={loading}
                      />}
                  </Suspense>
                }
              </div>
            </div>
          }
          {(!isMobile || tab === 1) &&
            <div className="home-grid">
              <div className="home-grid-item">
                <div className="home-paper">
                  <Faq />
                </div>
              </div>
            </div>}
        </div>
      </main>
      {isMobile && <BottomNavigation
        value={tab}
        onChange={(event: React.ChangeEvent<unknown>, newTab: number) => {
          if (newTab === 2) {
            // Trigger menu to pick language
            // @ts-ignore
            setMenuAnchorElement(event.currentTarget)
          } else {
            setTab(newTab);
            window.scroll({ top: 0, left: 0, behavior: 'smooth' })
          }
        }}
        showLabels
        className="home-bottom-nav"
      >
        <BottomNavigationAction label={t("appointments")} icon={<DateRangeIcon style={{ fontSize: 32 }} />} />
        <BottomNavigationAction label={t("faq")} icon={<HelpIcon style={{ fontSize: 32 }} />} />
        <BottomNavigationAction label={t("language")} icon={<LanguageIcon style={{ fontSize: 32 }} />} />
      </BottomNavigation>}
      {isMobile && (
        <Menu
          id="language-menu"
          anchorEl={menuAnchorElement}
          keepMounted
          open={Boolean(menuAnchorElement)}
          onClose={() => setMenuAnchorElement(null)}
        >
          {languages.map(language => (
            <MenuItem
              key={language[0]}
              onClick={() => {
                i18n.changeLanguage(language[0]);
                setMenuAnchorElement(null); // Close the language menu
              }}
            >
              {language[1]}
            </MenuItem>
          ))}
        </Menu>
      )}
    </div>
  );
}
